import axios from 'axios';

export async function getCommunity() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Community/Name`);
  return response.data;
}

export async function getHeightList() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Height/Name`);
  return response.data;
}

export async function getOccupationList() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Occupation/Name`);
  return response.data;
}

export async function getCountryList() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Country/Name`);
  return response.data;
}

export async function getStateList(Id) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/State/Name?CountryId=${Id}`);
  return response.data;
}

export async function getCityList(Id) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/City/Name?StateId=${Id}`);
  return response.data;
}

export async function getVillageList() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Village/Name`);
  return response.data;
}

export async function getEducationList() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Education/Name`);
  return response.data;
}

export async function getAnnualIncome() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/AnnualIncome/Name`);
  return response.data;
}

export async function getNotificationTypeList() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/NotificationType/Name`);
  return response.data;
}
