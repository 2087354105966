import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as Yup from 'yup';
import toastr from 'toastr';
import { Link, useHistory } from 'react-router-dom';
import * as actions from '../_redux/AuthActions';
import Recaptcha from 'react-google-invisible-recaptcha';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2/dist/sweetalert2.js';

const initialValues = {
  firstName: '',
  lastName: '',
  emailId: '',
  phoneNumber: '',
  countryCode: '+91',
  password: '',
  confirmPassword: '',
  acceptTerms: undefined,
};
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
function Registration(props) {
  const [t] = useTranslation('Auth');
  const dispatch = useDispatch();
  const history = useHistory();
  const { countryList } = useSelector(
    (state) => ({
      countryList: state.auth.countryList,
    }),
    shallowEqual
  );
  let reCaptchaObj = React.useRef();
  const RegistrationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(30, t('signUp.VALIDATION.FIRST_NAME_MAX'))
      .test('alphabets', t('signUp.VALIDATION.FIRST_NAME_ALPHABETS'), (value) => {
        return /^[A-Za-z]+$/.test(value);
      })
      .required(t('signUp.VALIDATION.FIRST_NAME_REQUIRED')),
    lastName: Yup.string()
      .max(30, t('signUp.VALIDATION.LAST_NAME_MAX'))
      .test('alphabets', t('signUp.VALIDATION.LAST_NAME_ALPHABETS'), (value) => {
        return /^[A-Za-z]+$/.test(value);
      })
      .required(t('signUp.VALIDATION.LAST_NAME_REQUIRED')),
    emailId: Yup.string()
      .email(t('signUp.VALIDATION.EMAIL_VALID'))
      .max(256, t('signUp.VALIDATION.EMAIL_MAX'))
      .required(t('signUp.VALIDATION.EMAIL_REQUIRED')),
    // .when("countryCode", {
    //   is: (val) => (val !== '+91' ? true : false),
    //   then: Yup.string().required("Email is required."),
    // })
    countryCode: Yup.string().required(t('signUp.VALIDATION.COUNTRY_REQUIRED')),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, t('signUp.VALIDATION.MOBILE_VALID'))
      .when('countryCode', {
        is: (val) => (val === '+91' ? true : false),
        then: Yup.string().min(10, t('signUp.VALIDATION.MOBILE_DIGITS')),
      })
      .min(6, t('signUp.VALIDATION.MOBILE_MIN'))
      .max(15, t('signUp.VALIDATION.MOBILE_MAX'))
      .required(t('signUp.VALIDATION.MOBILE_REQUIRED')),
    password: Yup.string()
      .min(6, t('signUp.VALIDATION.PASSWORD_MIN'))
      .max(100, t('signUp.VALIDATION.PASSWORD_MAX'))
      .required(t('signUp.VALIDATION.PASSWORD_REQUIRED')),
    confirmPassword: Yup.string()
      .required(t('signUp.VALIDATION.CONFIRM_PASSWORD_REQUIRED'))
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          t('signUp.VALIDATION.CONFIRM_PASSWORD_MATCH')
        ),
      }),
    acceptTerms: Yup.bool().required(t('signUp.VALIDATION.ACCEPT_TERMS_REQUIRED')),
    captchaResponse: Yup.string(),
  });

  useEffect(() => {
    dispatch(actions.fetchCountryList()).catch((error) => {
      Swal.fire({
        heightAuto: false,
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };
  let unmounted = false;
  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      setStatus();
      dispatch(
        actions.register(
          values.firstName,
          values.lastName,
          values.emailId,
          values.phoneNumber,
          values.countryCode,
          values.password,
          values.captchaResponse
        )
      )
        .then((response) => {
          if (!response.data.emailConfirmed) {
            toastr.success(`OTP sent successfully to ${response.data.email}.`, 'Patidar Sagai');
            history.push('/auth/verify-otp');
          }
          if (!unmounted) {
            setSubmitting(false);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setStatus(error.join('<br/>'));
        });
      unmounted = true;
    },
  });

  return (
    <div className="login-form login-signup" style={{ display: 'block' }}>
      {/*begin::Form*/}
      <form className="form" id="kt_login_signup_form" onSubmit={formik.handleSubmit}>
        {/*begin::Title*/}
        <div className="pb-13 pt-lg-0 pt-5">
          <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
            {t('signUp.SIGN_UP')}
          </h3>
          <p className="text-muted font-weight-bold font-size-h4">{t('signUp.LINE_1')}</p>
          <span className="text-muted font-weight-bold font-size-h4">
            {t('signUp.LINE_2')} ?{' '}
            <Link to="/auth/login" id="kt_login_signup" className="text-primary font-weight-bolder">
              {t('signUp.LOGIN_HERE')}
            </Link>
          </span>
        </div>
        {/*end::Title*/}
        {formik.status && (
          <div
            className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
            style={{ display: 'block' }}
          >
            <div
              className="alert-text font-weight-bold"
              dangerouslySetInnerHTML={{ __html: formik.status }}
            ></div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-6">
            {/*begin::Form group*/}
            <div className="form-group">
              <input
                autoFocus
                className={`form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6 ${getInputClasses(
                  'firstName'
                )}`}
                type="text"
                placeholder={t('signUp.FIRST_NAME')}
                name="firstName"
                autoComplete="off"
                {...formik.getFieldProps('firstName')}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.firstName}</div>
                </div>
              ) : null}
            </div>
            {/*end::Form group*/}
          </div>
          <div className="col-lg-6">
            {/*begin::Form group*/}
            <div className="form-group">
              <input
                className={`form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6 ${getInputClasses(
                  'lastName'
                )}`}
                type="text"
                placeholder={t('signUp.LAST_NAME')}
                name="lastName"
                autoComplete="off"
                {...formik.getFieldProps('lastName')}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.lastName}</div>
                </div>
              ) : null}
            </div>
            {/*end::Form group*/}
          </div>
        </div>
        {/*begin::Form group*/}
        <div className="row">
          <div className="col-lg-4">
            <select
              className={`form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6 ${getInputClasses(
                'countryCode'
              )}`}
              data-size="7"
              data-live-search="true"
              name="countryCode"
              {...formik.getFieldProps('countryCode')}
            >
              {/* <option value="">Country</option> */}
              {countryList.map((list) => {
                return (
                  <option key={list.id} value={list.code}>
                    {list.code} ({list.shortName})
                  </option>
                );
              })}
            </select>
            {formik.touched.countryCode && formik.errors.countryCode ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.countryCode}</div>
              </div>
            ) : null}
          </div>
          <div className="col-lg-8">
            <div className="form-group">
              <input
                className={`form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6 ${getInputClasses(
                  'phoneNumber'
                )}`}
                type="text"
                placeholder={t('signUp.PHONE_NUMBER')}
                name="phoneNumber"
                autoComplete="off"
                {...formik.getFieldProps('phoneNumber')}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.phoneNumber}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/*end::Form group*/}
        {/*begin::Form group*/}
        <div className="form-group">
          <input
            className={`form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6 ${getInputClasses(
              'emailId'
            )}`}
            type="email"
            placeholder={t('signUp.EMAIL')}
            name="emailId"
            autoComplete="off"
            {...formik.getFieldProps('emailId')}
          />
          {formik.touched.emailId && formik.errors.emailId ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.emailId}</div>
            </div>
          ) : null}
        </div>
        {/*end::Form group*/}
        {/*begin::Form group*/}
        <div className="form-group">
          <input
            className={`form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6 ${getInputClasses(
              'password'
            )}`}
            type="password"
            placeholder={t('signUp.PASSWORD')}
            name="password"
            autoComplete="off"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/*end::Form group*/}
        {/*begin::Form group*/}
        <div className="form-group">
          <input
            className={`form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6 ${getInputClasses(
              'confirmPassword'
            )}`}
            type="password"
            placeholder={t('signUp.CONFIRM_PASSWORD')}
            name="cpassword"
            autoComplete="off"
            {...formik.getFieldProps('confirmPassword')}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.confirmPassword}</div>
            </div>
          ) : null}
        </div>
        {/*end::Form group*/}
        {/*begin::Form group*/}
        <div className="form-group">
          <label className="checkbox mb-0">
            <input type="checkbox" name="acceptTerms" {...formik.getFieldProps('acceptTerms')} />
            <span />
            <div className="ml-2">
              {t('signUp.I_AGREE_THE')}
              <a href="https://patidarsagai.com/terms" target="_blank" rel="noreferrer">
                {' '}
                {t('signUp.TERMS_CONDITIONS')}
              </a>
              .
            </div>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/*end::Form group*/}
        {/*begin::Form group*/}
        <div className="form-group d-flex flex-wrap pb-lg-0 pb-3">
          <button
            type="button"
            id="kt_login_signup_submit"
            className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            onClick={() => {
              formik.setSubmitting(true);
              reCaptchaObj.current.reset();
              reCaptchaObj.current.execute();
            }}
          >
            <span>{t('signUp.SUBMIT')}</span>
            {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_signup_cancel"
              className="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
            >
              {t('signUp.CANCEL')}
            </button>
          </Link>
        </div>
        <Recaptcha
          ref={reCaptchaObj}
          sitekey="6LcpVDsaAAAAANOyJmqw0Z3jfOCP9vNtcmyzD6N5"
          onResolved={() => {
            const captchaResp = reCaptchaObj.current.getResponse();
            formik.setFieldValue('captchaResponse', captchaResp);
            formik.handleSubmit();
          }}
        />
        {/*end::Form group*/}
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default Registration;
