/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import toastr from 'toastr';
import { getBioData } from '../../../../../api';
import { checkIsActive } from '../../../../_helpers';

export function HeaderMenu({ layoutProps }) {
  const [t] = useTranslation('HeaderMenu');
  const [bioModal, setBioModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isAuthorized, profileAccess, profileId } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      profileAccess: auth.user && auth.user.profileStatus,
      profileId: auth.user && auth.user.profileId,
    }),
    shallowEqual
  );
  const location = useLocation();

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? 'menu-item-active' : '';
  };

  const getBioDataDetail = () => {
    setLoading(true);
    getBioData().then((response) => {
      if (response) {
        toastr.success('Bio Data Downloaded Successfully');
      }
      const blob = new Blob([response], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${profileId}.pdf`;
      link.click();
      setLoading(false);
      setBioModal(false);
    });
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      {isAuthorized ? (
        profileAccess === 3 ? (
          <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            {/* <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                "/dashboard"
              )}`}
            >
              <NavLink className="menu-link" to="/dashboard">
                <span className="menu-text">{t("DASHBOARD")}</span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </NavLink>
            </li> */}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            <li
              data-menu-toggle={layoutProps.menuDesktopToggle}
              aria-haspopup="true"
              className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
                '/matches'
              )}`}
            >
              <NavLink className="menu-link menu-toggle" to="/matches">
                <span className="menu-text">{t('MATCHES')}</span>
                <i className="menu-arrow"></i>
              </NavLink>
              <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                <ul className="menu-subnav">
                  {/*begin::1 Level*/}
                  <li className={`menu-item ${getMenuItemActive('/matches/new')}`}>
                    <NavLink className="menu-link" to="/matches/new">
                      <span className="menu-text">{t('NEW_MATCHES')}</span>
                    </NavLink>
                  </li>
                  {/*end::1 Level*/}
                  {/*begin::1 Level*/}
                  <li className={`menu-item ${getMenuItemActive('/matches/list')}`}>
                    <NavLink className="menu-link" to="/matches/list">
                      <span className="menu-text">{t('MY_MATCHES')}</span>
                    </NavLink>
                  </li>
                  {/*end::1 Level*/}
                  {/*begin::1 Level*/}
                  <li className={`menu-item ${getMenuItemActive('/matches/all')}`}>
                    <NavLink className="menu-link" to="/matches/all">
                      <span className="menu-text">{t('ALL_MATCHES')}</span>
                    </NavLink>
                  </li>
                  {/*end::1 Level*/}

                  {/*begin::2 Level*/}
                  <li className={`menu-item ${getMenuItemActive('/matches/patidar')}`}>
                    <NavLink className="menu-link" to="/matches/patidar">
                      <span className="menu-text">{t('PATIDAR_MATCHES')}</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {/*begin::3 Level*/}
                  <li className={`menu-item ${getMenuItemActive('/matches/recent')}`}>
                    <NavLink className="menu-link" to="/matches/recent">
                      <span className="menu-text">{t('RECENTLY_VIEWED')}</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}
                </ul>
              </div>
            </li>
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            <li
              data-menu-toggle={layoutProps.menuDesktopToggle}
              aria-haspopup="true"
              className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
                '/profile'
              )}`}
            >
              <NavLink className="menu-link menu-toggle" to="/profile">
                <span className="menu-text">{t('MY_PROFILE')}</span>
                <i className="menu-arrow"></i>
              </NavLink>
              <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                <ul className="menu-subnav">
                  {/*begin::2 Level*/}
                  <li className={`menu-item ${getMenuItemActive('/profile/overview')}`}>
                    <NavLink className="menu-link" to="/profile/overview">
                      <span className="menu-text">{t('MY_PROFILE')}</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}

                  {/*begin::2 Level*/}
                  <li className={`menu-item ${getMenuItemActive('/profile/partner-preferences')}`}>
                    <NavLink className="menu-link" to="/profile/partner-preferences">
                      <span className="menu-text">{t('PARTNER_PREFERENCES')}</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}

                  {/*begin::2 Level*/}
                  <li className={`menu-item ${getMenuItemActive('/profile/change-password')}`}>
                    <NavLink className="menu-link" to="/profile/change-password">
                      <span className="menu-text">{t('CHANGE_PASSWORD')}</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}

                  {/*begin::2 Level*/}
                  <li className={`menu-item ${getMenuItemActive('/profile/shortlist')}`}>
                    <NavLink className="menu-link" to="/profile/shortlist">
                      <span className="menu-text">{t('MY_SHORTLISTED')}</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}
                  {/*begin::2 Level*/}
                  <li className={`menu-item ${getMenuItemActive('/profile/shortlistedMe')}`}>
                    <NavLink className="menu-link" to="/profile/shortlistedMe">
                      <span className="menu-text">{t('SHORTLISTED_ME')}</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}
                  {/*begin::2 Level*/}
                  <li className={`menu-item ${getMenuItemActive('/profile/visitedMe')}`}>
                    <NavLink className="menu-link" to="/profile/visitedMe">
                      <span className="menu-text">{t('VISITED_ME')}</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}
                  {/*begin::2 Level*/}
                  <li className={`menu-item ${getMenuItemActive('/profile/satapeta')}`}>
                    <NavLink className="menu-link" to="/profile/satapeta">
                      <span className="menu-text">{t('SATAPETA_SETTING')}</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}
                  {/*begin::2 Level*/}
                  <li className={`menu-item ${getMenuItemActive('/profile/notification-setting')}`}>
                    <NavLink className="menu-link" to="/profile/notification-setting">
                      <span className="menu-text">{t('NOTIFICATION_SETTING')}</span>
                      <span className="menu-label">
                        <span className="label label-danger label-inline">new</span>
                      </span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}
                  {/*begin::2 Level*/}
                  <li className={`menu-item ${getMenuItemActive('/profile/account')}`}>
                    <NavLink className="menu-link" to="/profile/account">
                      <span className="menu-text">{t('ACCOUNT_INFORMATION')}</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}
                </ul>
              </div>
            </li>
            {/*end::1 Level*/}

            {/*Classic submenu*/}
            {/*begin::1 Level*/}
            {/* <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/matches')}`}>
                <NavLink className="menu-link menu-toggle" to="/matches">
                    <span className="menu-text">Matches</span>
                    <i className="menu-arrow"></i>
                </NavLink> */}
            {/* <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav"> */}

            {/*begin::2 Level*/}
            {/* <li className={`menu-item ${getMenuItemActive('/matches')}`}>
                            <NavLink className="menu-link" to="/matches">
                                <span className="menu-text">My Matches</span>
                            </NavLink>
                        </li> */}
            {/*end::3 Level*/}

            {/*begin::2 Level*/}
            {/* <li className={`menu-item ${getMenuItemActive('/matches/recent')}`}>
                            <NavLink className="menu-link" to="/matches/recent">
                                <span className="menu-text">Recently Viewed</span>
                            </NavLink>
                        </li> */}
            {/*end::3 Level*/}

            {/* </ul>
                </div> */}
            {/* </li> */}

            {/*Classic submenu*/}
            {/*begin::1 Level*/}
            {/* <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/search')}`}>
                <NavLink className="menu-link menu-toggle" to="/search">
                    <span className="menu-text">Search</span>
                    <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav"> */}

            {/*begin::2 Level*/}
            {/* <li className={`menu-item ${getMenuItemActive('/search')}`}>
                            <NavLink className="menu-link" to="/search">
                                <span className="menu-text">Basic Search</span>
                            </NavLink>
                        </li> */}
            {/*end::3 Level*/}

            {/*begin::2 Level*/}
            {/* <li className={`menu-item ${getMenuItemActive('/search/advanced')}`}>
                            <NavLink className="menu-link" to="/search/advanced">
                                <span className="menu-text">Advanced Search</span>
                            </NavLink>
                        </li> */}
            {/*end::3 Level*/}

            {/* </ul>
                </div>
            </li> */}
            {/*end::1 Level*/}

            {/*Classic submenu*/}
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/inbox')}`}>
              <NavLink className="menu-link" to="/inbox">
                <span className="menu-text">{t('INBOX')}</span>
                {/* <i className="menu-arrow"></i> */}
              </NavLink>
            </li>
            <li className="menu-item menu-item-rel">
              <a className="menu-link" onClick={() => setBioModal(true)}>
                <span className="menu-text">{t('BIO-DATA')}</span>
                <i className="menu-arrow"></i>
              </a>
            </li>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/ticket')}`}>
              <NavLink className="menu-link" to="/ticket">
                <span className="menu-text">{t('SUPPORT_TICKET')}</span>
                {/* <i className="menu-arrow"></i> */}
              </NavLink>
            </li>
            {/*end::1 Level*/}
          </ul>
        ) : null
      ) : (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li className={`menu-item menu-item-rel ${getMenuItemActive('/home')}`}>
            <NavLink className="menu-link" to="/home">
              <span className="menu-text">{t('HOME')}</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li className={`menu-item menu-item-rel ${getMenuItemActive('/about')}`}>
            <NavLink className="menu-link" to="/about">
              <span className="menu-text">{t('ABOUT')}</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          {/* <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/gallery"
            )}`}
          >
            <NavLink className="menu-link" to="/gallery">
              <span className="menu-text">{t("GALLERY")}</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive("/news")}`}
          >
            <NavLink className="menu-link" to="/news">
              <span className="menu-text">{t("NEWS")}</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/events"
            )}`}
          >
            <NavLink className="menu-link" to="/events">
              <span className="menu-text">{t("EVENTS")}</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li> */}
          <li className={`menu-item menu-item-rel ${getMenuItemActive('/contact')}`}>
            <NavLink className="menu-link" to="/contact">
              <span className="menu-text">{t('CONTACT')}</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        </ul>
      )}
      {/*end::Header Nav*/}
      {bioModal ? (
        <Modal show={bioModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t('BIO-DATA-MODAL.TITLE')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              {' '}
              <i className="fas fa-file-pdf text-success icon-5x mb-2"></i>
              <br />
              <span>{t('BIO-DATA-MODAL.INFO-1')}</span>
              <br />
              <span>{t('BIO-DATA-MODAL.INFO-2')}</span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              name="close"
              disabled={loading}
              variant="secondary"
              onClick={() => setBioModal(false)}
            >
              {t('BIO-DATA-MODAL.BTN-CLOSE')}
            </Button>
            <Button
              name="download"
              variant="success"
              className="text-center"
              disabled={loading}
              onClick={() => {
                getBioDataDetail();
              }}
            >
              {loading ? (
                <span className="">Downloading...</span>
              ) : (
                t('BIO-DATA-MODAL.BTN-DOWNLOAD')
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  );
}
